/**
 * DataStreamListEventType Enums. These should remain consistent with the counterparts
 * defined in JUSTICE DMS: com.sti.justice.dms.enums.DataStreamListEventTypeEnum.
 */
export const DslEventTypeEnum = {
     GENERIC: 0,
     DATA_MISSING_ALERT_ENABLED: {name: "DATA_MISSING_ALERT_ENABLED"}, value: 1 ,
     DATA_MISSING_ALERT_DISABLED: {name: "DATA_MISSING_ALERT_DISABLED", value: 2},
     DATA_MISSING_ALERT_TRIGGERED: {name: "", value: 7},
     REL_EXCEEDANCE_TRIGGERED: {name: " REL_EXCEEDANCE_TRIGGERED", value: 3},
     REL_EXCEEDANCE_RESOLVED: {name: "REL_EXCEEDANCE_RESOLVED", value: 4},
     MAINTENANCE_MODE_ENABLED: {name: "MAINTENANCE_MODE_ENABLED", value: 5},
     MAINTENANCE_MODE_DISABLED: {name: "MAINTENANCE_MODE_DISABLED", value: 6},
     
};


export const ContentViewEnum = {
     DSL_INVENTORY_MANAGEMENT: "DSL_INVENTORY_MANAGEMENT",
     DSL_EVENT_HEATMAP: "DSL_EVENT_HEATMAP",
};

export const MaintenanceModeOptionsEnum = {
     NONE: "NONE",
     PLANNED: "PLANNED",
     URGENT: "URGENT"
};




export function descendingComparator(a, b, orderBy) {

    let aVal = a[orderBy];
    let bVal = b[orderBy];

    // MDC and MMC props are a bit deeper in the object so we need to fish them out
    if (orderBy === "missingDataCheck" || orderBy === "maintenanceModeCheck") {
        aVal = a.json[orderBy].isEnabled;
        bVal = b.json[orderBy].isEnabled;

    }

    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }

    return 0;
}

export function getComparator(order, orderBy) {
    const temp = order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);

    return temp;
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useSelector} from "react-redux";
import {getDslListByAgency, setPendingAgencyTransition, selectAppUi, saveDslList, selectAppData} from "../../store/appSlice";
import {useDispatch} from "react-redux";
import TextField from '@material-ui/core/TextField';
import config from "../../config/config";
import cloneDeep from "lodash/cloneDeep";
import {DslEventTypeEnum} from "../../config/APP_CONSTANTS";
import { loadFromLocalStorage } from '../../store/localStorage';


export default function SaveDslChangesButton() {

    const [openDialog, setOpenDialog] = React.useState(false);

    const uiState = useSelector(selectAppUi);

    const appDataState = useSelector(selectAppData);

    const agencies = loadFromLocalStorage("foundAgencies"); //INS 1229
    const currentAgency = agencies[uiState.selectedAgency.index];

    const dslList = appDataState[currentAgency.code];

    return (

        <div>

            <Button variant="contained"
                    color="primary" disabled={(!uiState.isDirty || uiState.isLoading)}
                    onClick={() => setOpenDialog(true)} >
                Save Changes
            </Button>

            <SaveDslChangesDialog
                dslList={dslList}
                agencyCode={currentAgency.code}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
            />

            <DiscardUnsavedDslChangesDialog
                selectedAgency={uiState.selectedAgency}
            />

        </div>

    );

};


 function DiscardUnsavedDslChangesDialog(props) {

    const dispatch = useDispatch();

    const handleClose = (discardChanges) => {
        if (discardChanges) {
            dispatch(getDslListByAgency(props.selectedAgency.isPendingTargetIndex))
        } else {
            dispatch(setPendingAgencyTransition({isPendingTransition: false, isPendingTargetIndex: null}))
        }
    }

    return (

        <div>
            <Dialog
                open={props.selectedAgency.isPendingTransition}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Discard Unsaved Changes?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Select "OK" to proceed and discard your unsaved changes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() =>handleClose(true)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>

    );
};


function SaveDslChangesDialog(props) {

    const {dslList, agencyCode, setOpenDialog} = props;

    const [note, setNote] = React.useState(null);

    const dispatch = useDispatch();

    const dirtyDslList =  [];

    Object.values(dslList).forEach((inDsl) => {

        let dsl = cloneDeep(inDsl);

        dsl.events = [];

        if (dsl.json.maintenanceModeCheck.isEnabledOriginal !== dsl.json.maintenanceModeCheck.isEnabled ||
            dsl.json.maintenanceModeCheck.isPlannedOriginal !== dsl.json.maintenanceModeCheck.isPlanned
        ) {
            const mmcEvent = (dsl.json.maintenanceModeCheck.isEnabled) ? DslEventTypeEnum.MAINTENANCE_MODE_ENABLED : DslEventTypeEnum.MAINTENANCE_MODE_DISABLED;
            dsl.eventTypes.push(mmcEvent.name);
        }

        if (dsl.json.missingDataCheck.isEnabledOriginal !== dsl.json.missingDataCheck.isEnabled) {
            const mdcEvent = (dsl.json.missingDataCheck.isEnabled) ? DslEventTypeEnum.DATA_MISSING_ALERT_ENABLED : DslEventTypeEnum.DATA_MISSING_ALERT_DISABLED;
            dsl.eventTypes.push(mdcEvent.name);
        }

        if (dsl.eventTypes.length > 0) {
            dirtyDslList.push(dsl);
        }

    });

    const listChangedItems = () => {
        return dirtyDslList.map((listItem) => {     
            return <li key={listItem.name}>
            {listItem.name}
            <strong>
                {(() => {
                    if (listItem.eventTypes[0] === "MAINTENANCE_MODE_ENABLED") {
                        if (!listItem.json.maintenanceModeCheck.isPlanned) {
                            return  " Urgent (Unplanned) Maintenance Enabled" 
                        } else if (listItem.json.maintenanceModeCheck.isPlanned) {
                            return " Maintenance Mode Planned"
                        } 
                    } else if (listItem.eventTypes[0] === "MAINTENANCE_MODE_DISABLED") {
                        return " Maintenance Mode Disabled (Normal Operations)" 
                    }
                })()}
            </strong>
            <strong>
            {(() => {
                    if (listItem.json.missingDataCheck.isEnabled && !listItem.json.missingDataCheck.isEnabledOriginal) {
                        return  ", Missing Data Check Enabled " 
                    } else if (!listItem.json.missingDataCheck.isEnabled && listItem.json.missingDataCheck.isEnabledOriginal) {
                        return ", Missing Data Check Disabled " 
                    }
                })()}
            </strong>
            </li>

    });
    }

    const handleSaveChanges = () => {

        if (dirtyDslList.length > 0) {
            dispatch(
                saveDslList({dslList: dirtyDslList, agencyCode: agencyCode, note: note})
            );
        }

        setNote(null);
        props.setOpenDialog(false);

    };

    return (

        <Dialog open={props.openDialog}
                onClose={() => {
                    setNote(null);
                    props.setOpenDialog(false);
                }}
                aria-labelledby="form-dialog-title"  >
            <DialogTitle id="form-dialog-title">Save Changes</DialogTitle>
            <DialogContent>
                <h3>You are making one or more changes to the following devices:</h3>
                <ul className="confirmationList">
                    {listChangedItems()}
                </ul>

                <DialogContentText>
                    Please provide a reason for your changes (optional).
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="E.g. bump testing, instrument malfunction, etc."
                    type="text"
                    fullWidth
                    onChange={(e) => setNote(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setNote(null);
                    props.setOpenDialog(false);
                }}
                        color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSaveChanges} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>

    );

};


import qs from 'querystring';
import config from '../config/config';
import {loadFromLocalStorage} from "../store/localStorage";

const objectToQueryString = (obj) => {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}


const API_BASE_URL =  config.apiBaseUrl;

export const InsightApi = {

    loginAsUser : (username, password) => {


        return fetch(  API_BASE_URL + "Auth/User/login", {
            method: "POST",
            headers: {
               "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({
                username: username,
                password: password
            })
        });


    },

    keepSessionAlive : (token) => {

        console.debug("Refreshing API token ", token);

        return fetch(  API_BASE_URL + "Auth/session/keepalive", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({
                token: token
            })
        });


    },

    //INS-1229
    getAgencyListByUser : (token) => {
        console.debug("Getting Authorized Agency List ", token);
        
        return fetch(  API_BASE_URL + "/agency/listagencies", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({
                token: token
            })
        });


    },

    getDslListByAgency : (agencyGuid) => {

        const queryParams = objectToQueryString({
            token: loadFromLocalStorage("auth").token,
            agencyGuid: agencyGuid
        });

        return fetch( API_BASE_URL + "datastreamlist/getbyagency/?" + queryParams, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });

    },


    saveDsl : (dsl, note) => {

        const queryParams = objectToQueryString({
            token: loadFromLocalStorage("auth").token
        });

        const data = new URLSearchParams();
        data.append("dataStreamList", JSON.stringify(dsl));
        data.append("eventTypes", dsl.eventTypes.join());
        data.append("note", note);

        return fetch(  API_BASE_URL + "datastreamlist/save/?" + queryParams, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data
        });

    },


    getDataStreamData : (dsId, startDtMillis, endDtMillis) => {

        const queryParams = objectToQueryString({
            token: loadFromLocalStorage("auth").token,
            dataStreamId : dsId,
            startDtMillis: startDtMillis,
            endDtMillis: endDtMillis
        });


        return fetch(  API_BASE_URL + 'TimeSeriesData/getByDataStreamId/?' + queryParams, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            //body: data
        });


    }

 };
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {InsightApi} from '../api/insightApi';
import {removeFromLocalStorage, persistToLocalStorage, loadFromLocalStorage} from "./localStorage";
import config from '../config/config';
import {ContentViewEnum, MaintenanceModeOptionsEnum} from '../config/APP_CONSTANTS';
import moment from 'moment';
import { ContactsOutlined } from '@material-ui/icons';

const INITIAL_UI_STATE = {
    isLoading: false,
    updateActions: [],
    isLoginError: false,
    agencyListEmpty: false,
    selectedAgency: {
        index: 0,
        isPendingTransition: false,
        isPendingTargetIndex: null
    },
    viewType : {
        dslInventoryManagement: {
            isActive: true,
            selectedDsl : {
                dslId : null,
                selectedDataStream: {
                    dataStream: null,
                    isLoading: false,
                    timeSeriesData: []
                },
            }

        },
        eventHeatmap: {
            isActive: false
        }
    },
    message: {
        isActive: false,
        text: ""
    }
}


const DEFAULT_ERROR_MSG = "An internal error has occurred. Reach out to the development team for additional assistance."

/********** THUNKS ***************/
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const login = createAsyncThunk(

    'auth/login',

      async (credentials) => {

        // Chained auth & initial data request
        const response = await InsightApi.loginAsUser(
            credentials.username,
            credentials.password
        ).then(pendingLoginResponse => { // Pending login

            return pendingLoginResponse.json();

        }).then(token => { // Fulfilled login

            const auth = {
                token: token,
                _dt: moment.now()
            }

            persistToLocalStorage("auth", auth);
            const response =  InsightApi.getAgencyListByUser(auth.token) //INS 1229
                .then(pendingResponse => { // fulfilled but not resolved
                     return pendingResponse.json()
                }).then(agenciesFromAPI => { // Fulfilled

                    if (agenciesFromAPI.length === 0) {
                        return {
                            agencyListEmpty: true
                        }
                    }

                    let foundAgencies = new Array();
                    agenciesFromAPI.forEach(agencyFromAPI => {
                        Object.values(config.agencies).map((a, i) => {
                            if(a.guid === agencyFromAPI.uniqueKey){
                                console.debug("Found access to agency " + a.name);
                                foundAgencies.push(a);
                            }
        
                        })
                    });
                    persistToLocalStorage("foundAgencies", foundAgencies); 
                    // Initial data request. Fetch DSL for every agency
                    const response = Promise.all(Object.values(foundAgencies).map((a, i) => {
                            return InsightApi.getDslListByAgency(
                                a.guid
                            );
                        })

                    ).then(function (responses) { // Pending

                        return Promise.all(responses.map(function (response) {
                            return response.json();
                        }));

                    }).then(function (data) { // Fulfilled
                        const dslDataByAgency = {};
                        data.forEach( (dslList, i) => {
                            const dslDataWithEventInfo = dslList.data.map( (dsl)=> {

                                dsl.eventTypes = [];
                                dsl.json.maintenanceModeCheck.isEnabledOriginal = dsl.json.maintenanceModeCheck.isEnabled;
                                dsl.json.missingDataCheck.isEnabledOriginal = dsl.json.missingDataCheck.isEnabled;
                                dsl.json.maintenanceModeCheck.isPlannedOriginal = dsl.json.maintenanceModeCheck.isPlanned;

                                return dsl;
                            });

                            dslDataByAgency[dslList.agencyCode] = dslDataWithEventInfo;
                        });

                        return {
                            auth: auth,
                            data: dslDataByAgency
                        }

                    }).catch(function (error) {
                        console.error(error);
                    });
                    return response;
                }).catch(function (error) {
                    console.error(error);
                });//
          return response;
        }); 
        return response;
   }
);

export const keepAlive = createAsyncThunk(

    'auth/keepalive',

    async () => {

        const auth =  loadFromLocalStorage("auth");

        if (auth.token) {

            const response = await InsightApi.keepSessionAlive(
                auth.token
            ).then(pendingResponse => { // fulfilled but not resolved

                return pendingResponse.json();

            }).then(response => { // Fulfilled login

                const updatedAuth = {
                    token: auth.token,
                    _dt: moment.now()
                }

                return updatedAuth;


            });

            return response;

        }

    }


);


export const saveDslList = createAsyncThunk(

    'app/data/persistDirtyDslLists',

    async (payload) => {

        let response = await Promise.all(

            payload.dslList.map( (dsl) => {

                return InsightApi.saveDsl(
                    dsl,
                    payload.note
                ).then((response) => {
                    return response.json();
                });

            })


        ); // Promise settled, but not fulfilled. Go to extraReducers for that magic

        return (response);

    }
);

export const changeSelectedDataStream = createAsyncThunk(

    'app/data/fetchDsData',

    async (payload) => {


        let response = InsightApi.getDataStreamData(payload.dataStream.dataStreamId, payload.startDtMillis, payload.endDtMillis)

            .then(pendingResponse => { // fulfilled but not resolved

                return pendingResponse.json()


        }).then(fulfilledResponse => { // Fulfilled

           return  fulfilledResponse;

        });

        return response;

    }


);



export const getDslListByAgency = createAsyncThunk(

    'app/ui/getDslListByAgency',

    async (agencyKey, {getState}) => {

        const uiState = getState().app.ui;

        // Resolve the promise. Skip the fetch

        // TODO: Avoid triggering this action altogether by moving this "isDirty"
        // check upstream. The one problem we ran into was that the action can be dispatched
        // from inside a setInterval async call from where we don't have access to the up-to-date
        // copy of the state
        if (uiState.isDirty && !uiState.selectedAgency.isPendingTransition) {
            return{didAbortAction: true};
        }

        const agencies = loadFromLocalStorage("foundAgencies"); //INS 1229
        const agencyGuid = agencies[agencyKey].guid;

        const response = await InsightApi.getDslListByAgency(
            agencyGuid,
        ); // Promise settled, but not fulfilled. Go to extraReducers for that magic

        return response.json();

    }
);


/********* SLICES (A.K.A. REDUCERS & ACTION CREATORS) ****************/
export const appSlice = createSlice({

  name: 'app',

  initialState: {
      auth: loadFromLocalStorage("auth"),
      data : loadFromLocalStorage("data") || {},
      ui: INITIAL_UI_STATE
  },  // Reducers must always specify their initial state. https://stackoverflow.com/a/36657621/1642169

  reducers : {

     /************ AUTH STATE REDUCERS  *****************/
     logout : (state, action) => {

         removeFromLocalStorage("auth");
         removeFromLocalStorage("data");
         removeFromLocalStorage("foundAgencies")
         state.auth = null;
         state.data = {};
         state.ui = INITIAL_UI_STATE;
     },


    /****** UI STATE REDUCERS ***********/
    toggleMaintenanceModeCheck : (state, action) => {
        // Toggle maintenance-mode check by dataStreamId
        //
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        //
        // Word of warning: (GXP) Immer also makes it harder to inspect the state object here.
        // A wrapped proxy "draft" is provided instead.
        const i = state.data[action.payload.agencyCode].findIndex(dsl => dsl.uniqueKey === action.payload.uniqueKey);


        switch(action.payload.selectedMaintenance) {
            case MaintenanceModeOptionsEnum.PLANNED:
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isEnabled = true;
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isPlanned = true;
                break;
            case MaintenanceModeOptionsEnum.URGENT:
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isEnabled = true;
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isPlanned = false;
                break;
            case MaintenanceModeOptionsEnum.NONE:
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isEnabled = false;
                state.data[action.payload.agencyCode][i].json.maintenanceModeCheck.isPlanned = true;
        }

        state.ui.isDirty = true;

    },


    toggleMissingDataCheck : (state, action) => {

        const i = state.data[action.payload.agencyCode].findIndex(dsl => dsl.uniqueKey === action.payload.uniqueKey);

        const isEnabledPrevState = state.data[action.payload.agencyCode][i].json.missingDataCheck.isEnabled;

        state.data[action.payload.agencyCode][i].json.missingDataCheck.isEnabled = !isEnabledPrevState;

        state.ui.isDirty = true;
    },


    closeMessage : (state, action) => {
        state.ui.message = {
          text: "",
          isActive: false
        };
    },

    setPendingAgencyTransition : (state, action) => {
        state.ui.selectedAgency.isPendingTransition = action.payload.isPendingTransition;
        state.ui.selectedAgency.isPendingTargetIndex = action.payload.isPendingTargetIndex;
    },


    changeSelectedViewType : (state, action) => {
        if (action.payload === ContentViewEnum.DSL_INVENTORY_MANAGEMENT) {
            state.ui.viewType.dslInventoryManagement.isActive = true;
            state.ui.viewType.eventHeatmap.isActive = false;
        } else {
            state.ui.viewType.dslInventoryManagement.isActive = false;
            state.ui.viewType.eventHeatmap.isActive = true;
        }

    },

    changeSelectedDsl : (state, action) => {
        state.ui.viewType.dslInventoryManagement.selectedDsl.dslId = action.payload;
        state.ui.viewType.dslInventoryManagement.selectedDsl.selectedDataStream = INITIAL_UI_STATE.viewType.dslInventoryManagement.selectedDsl.selectedDataStream;
    }


  },


  extraReducers : {

      /************ AUTH STATE EXTRA REDUCERS  *****************/
      [login.pending] : (innerState, action) => {
          innerState.ui.isLoading = true;
          innerState.ui.isLoginError = false;
      },

       [login.rejected] : (innerState, action) => {
            innerState.ui.isLoginError = true;
            innerState.ui.isLoading = false;
       } ,

      [login.fulfilled] : (innerState, action) => {
          if (action.payload.agencyListEmpty) {
              innerState.ui.message = {
                  isActive: true,
                  text: "This account is either not authorized to access any agencies or there is a misconfiguration in the system. Please contact system administrator if this issue persists."
              };
              innerState.ui.agencyListEmpty = true;
          } else {
              innerState.auth = action.payload.auth;
              innerState.data = action.payload.data;
              persistToLocalStorage("auth", action.payload.auth);  // Store auth in localStorage to have it available after a page refresh
              persistToLocalStorage("data", innerState.data);
          }

          innerState.ui.isLoginError = false;
          innerState.ui.isLoading = false;
      },


      [keepAlive.fulfilled] : (innerState, action) => {
          innerState.auth = action.payload;
          innerState.ui.isLoading = false;
          persistToLocalStorage("auth", action.payload);
      },


      /************ UI STATE EXTRA REDUCERS *****************/
      [saveDslList.pending] : (state, action) => {
          state.ui.isLoading = true;
      },

      [saveDslList.fulfilled] : (innerState, action) => {
          

          if ( !action.payload[0].isFailure && !action.payload[0].error) {

              // Update ui "dirty" state
              innerState.ui.isLoading = false;
              innerState.ui.isDirty = false;
              innerState.ui.message = {
                  isActive: true,
                  text: "Saved!"
              };

              const agencyCode = action.payload[0].agencyCode;

              innerState.data[agencyCode].forEach( (dsl, i) => {

                  dsl.eventTypes = [];
                  dsl.json.maintenanceModeCheck.isEnabledOriginal = innerState.data[agencyCode][i].json.maintenanceModeCheck.isEnabled;
                  dsl.json.maintenanceModeCheck.isPlannedOriginal = innerState.data[agencyCode][i].json.maintenanceModeCheck.isPlanned;
                  dsl.json.missingDataCheck.isEnabledOriginal = innerState.data[agencyCode][i].json.missingDataCheck.isEnabled;
                  dsl.isDirty = false;

              });

              persistToLocalStorage("data",  innerState.data);


          } else {

              console.error("Unable to fulfill request", action.payload[0]);

              if (action.payload[0].messages[0].includes("Access restricted.")) {
                  removeFromLocalStorage("auth");
                  removeFromLocalStorage("data");
                  innerState.auth = null;
                  innerState.data = {};
                  innerState.ui.isLoading = false;
                  innerState.ui.isLoginError = false;
                  innerState.ui.isDirty = false;
                  innerState.ui.selectedAgency.index = 0;
                  innerState.ui.selectedAgency.isPendingTransition = false;
                  innerState.ui.selectedAgency.isPendingTargetIndex = false;
                  innerState.ui.message.isActive = false;
                  innerState.ui.message.text = "";

              }  else {
                  innerState.ui.message = {
                      isActive: true,
                      text: DEFAULT_ERROR_MSG
                  };
                  innerState.ui.isLoading = false;
              }

          }

      },

      [getDslListByAgency.pending] : (innerState, action) => {
          innerState.ui.selectedAgency.index = action.meta.arg;
          innerState.ui.isLoading = true;

      },

      [getDslListByAgency.fulfilled] : (innerState, action) => {

          if (action.payload.didAbortAction) {
              console.info("State is dirty. Skipping dsl inventory refresh");
          }

          else if ( !action.payload.error) {


              const dslDataWithEventInfo = action.payload.data.map( (dsl)=> {

                  dsl.eventTypes = [];
                  dsl.json.maintenanceModeCheck.isEnabledOriginal = dsl.json.maintenanceModeCheck.isEnabled;
                  dsl.json.maintenanceModeCheck.isPlannedOriginal = dsl.json.maintenanceModeCheck.isPlanned;
                  dsl.json.missingDataCheck.isEnabledOriginal = dsl.json.missingDataCheck.isEnabled;

                  return dsl;
              });

              innerState.data[action.payload.agencyCode] = dslDataWithEventInfo;
              innerState.ui.isDirty = false;
              innerState.ui.selectedAgency.isPendingTransition = false;
              innerState.ui.selectedAgency.isPendingTargetIndex = null;
              persistToLocalStorage("data",  innerState.data);

              if (innerState.auth) { // Refresh auth _dt

                  const updatedAuth = {
                      token: innerState.auth.token,
                      _dt:moment.now()
                  };

                  innerState.auth = updatedAuth;
                  persistToLocalStorage("auth", updatedAuth);
              }


          } else {

              console.error("Unable to fulfill request", action.payload.messages);

              if (action.payload.messages[0].includes("Access restricted.")) {
                    removeFromLocalStorage("auth");
                    removeFromLocalStorage("data");
                    innerState.auth = null;
                    innerState.data = {};
                    innerState.ui.isLoading = false;
                    innerState.ui.isLoginError = false;
                    innerState.ui.isDirty = false;
                    innerState.ui.selectedAgency.index = 0;
                    innerState.ui.selectedAgency.isPendingTransition = false;
                    innerState.ui.selectedAgency.isPendingTargetIndex = false;
                    innerState.ui.message.isActive = false;
                    innerState.ui.message.text = "";
              } else {
                  innerState.ui.message = {
                      isActive: true,
                      text: DEFAULT_ERROR_MSG
                  };
              }

          }

          innerState.ui.isLoading = false;
      },


      [getDslListByAgency.rejected] : (innerState, action) => {

          innerState.ui.isLoading = false;

          innerState.ui.message = {
            isActive: true,
            text: DEFAULT_ERROR_MSG
          };

          console.error(action.error);

      },


      [changeSelectedDataStream.pending] : (innerState, action) => {
          innerState.ui.viewType.dslInventoryManagement.selectedDsl.selectedDataStream.isLoading = true;
      },

      [changeSelectedDataStream.fulfilled] : (innerState, action) => {

          if (action.payload.isFailure) {
              innerState.ui.message = {
                  isActive: true,
                  text: "Unable to retrieve historical data for the selected dataStream"
              }
          } else {

              innerState.ui.viewType.dslInventoryManagement.selectedDsl.selectedDataStream = {
                  dataStream: action.meta.arg.dataStream,
                  timeSeriesData: action.payload.timeSeriesData
              }

          }

          innerState.ui.viewType.dslInventoryManagement.selectedDsl.selectedDataStream.isLoading = false;

      },

      [changeSelectedDataStream.rejected] : (innerState, action) => {
          innerState.ui.isLoading = false;
          innerState.ui.message = {
              isActive: true,
              text: DEFAULT_ERROR_MSG
          };
          console.error(action.error);

      }

  }

});

export const {
    toggleMaintenanceModeCheck,
    toggleMissingDataCheck,
    setPendingAgencyTransition,
    logout,
    closeMessage,
    changeSelectedViewType,
    changeSelectedDsl
} = appSlice.actions;



/******* SELECTORS *************/

// The functions below are called a selector and allow us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)
export const selectAppData  = state => state.app.data;

export const selectAppUi = state => state.app.ui;

export const selectDataStream = state => state.app.ui.viewType.dslInventoryManagement.selectedDsl.selectedDataStream;

export const selectDsl = state => state.app.ui.viewType.dslInventoryManagement.selectedDsl;

export const selectAuth = state => state.app.auth;

export const selectAuthToken = (state) =>  {
    if (state.app.auth) {
        return state.app.auth.token;
    }
    return null;
};

export default appSlice.reducer;


const API_LOCALHOST = "http://localhost:8088/api/";
const API_URL_STAGING = "https://insightdms-staging.sonomatech.com/api/";
const API_URL_PRODUCTION =  "https://insight2-data.sonomatech.com/api/";

const env = process.env.REACT_APP_ENVIRONMENT || "production"

const getApiUrl = () => {
    if (process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL
    } else {
        if (env === 'development') {
            return API_LOCALHOST;
        } else if(env === 'staging') {
            return API_URL_STAGING;
        } else {
            return API_URL_PRODUCTION;
        }
    }
}


const config = {

    apiBaseUrl: getApiUrl(),

    agencies : {

        0: {
            name: "Torrance, Community",
            code: "TOR",
            guid: "cef06022-976a-11e9-9d5f-06127cf5d3b4"
        },


        1: {
            name: "Marathon, Martinez",
            code: "MTZ",
            guid: "ecc7be99-97a4-11e9-9d5f-06127cf5d3b4"
        },


        2 : {
            name: "Marathon, Los Angeles",
            code: "LAR",
            guid: "3732bfc2-d5aa-11e9-9d5f-06127cf5d3b4"
        },


        3 : {
            name: "Chevron, El Segundo",
            code: "CVX",
            guid: "8836faf7-15f4-11ea-891d-020c526f0bd1"
        },


        4 : {
            name: "Chevron, Richmond",
            code: "CVR",
            guid: "8bce25b6-e5f4-11e9-9d5f-06127cf5d3b4"
        },

        5 : {
            name: "Valero, Benicia",
            code: "VLO",
            guid: "87d9be41-50b7-11e9-9efe-0050568d07f7"
        },

        6: {
            name: "Kern Oil",
            code: "KOR",
            guid: (env === 'production' ? "8ca197e8-fa21-11eb-b757-06127cf5d3b4" : "5e788f03-fa1d-11eb-ba31-020c526f0bd1")
        },

        7: {
            name: "Imperial County",
            code: "ICAPCD",
            guid: (env === 'production' ? "ace11302-6cbf-11ec-b757-06127cf5d3b4" : "7dbc08be-1bde-11ec-ba31-020c526f0bd1")
        },

        8: {
            name: "Phillips 66",
            code: "P66",
            guid: (env === 'production' ? "0a20f8fb-ac5e-11ec-b757-06127cf5d3b4" : "b7b3f939-89dd-11ec-ba31-020c526f0bd1")
        },

        9: {
            name: "Phillips 66 Rodeo",
            code: "P66ROD",
            guid: (env === 'production' ? "f52bdb17-80c2-11ed-b757-06127cf5d3b4" : "2fcde854-80a2-11ed-ba31-020c526f0bd1")
        },
        10: {
            name: "SCAQMD Salton Sea",
            code: "SALTON",
            guid: (env === 'production' ? "ee63b407-c747-11ec-b757-06127cf5d3b4" : "ba0681bd-3cc0-11ec-ba31-020c526f0bd1")
        },
        11: {
            name: "Richmond Water",
            code: "RCHWTR",
            guid: (env === 'production' ? "1342200a-e638-11ed-b757-06127cf5d3b4" : "2da68175-e454-11ed-ba31-020c526f0bd1")
        },
        12: {
            name: "SPRC Thailand",
            code: "SPRC",
            guid: (env === 'production' ? "6bfc6e08-b3fe-11ee-b757-06127cf5d3b4" : "5bcf4287-93ad-11ee-8708-020c526f0bd1")
        },
        13: {
            name: "World Oil",
            code: "WOIL",
            guid: (env === 'production' ? "1798d881-d0e2-11ee-b757-06127cf5d3b4" : "5bd6dddd-d03b-11ee-8708-020c526f0bd1")
        },
        14: {
            name: "DOE SanJoaquin",
            code: "DOESANJOAQ",
            guid: (env === 'production' ? "2e331fe8-3d47-11ef-b757-06127cf5d3b4" : "f0e7a441-299e-11ef-8708-020c526f0bd1")
        },
        15: {
            name: "DOE Denver",
            code: "DOEDENVER",
            guid: (env === 'production' ? "a61f46c4-3d47-11ef-b757-06127cf5d3b4" : "a9bec3ea-299f-11ef-8708-020c526f0bd1")
        }

    }



};

export default config;